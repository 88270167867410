
import { Vue, Component, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import CustomSwitch from '@/modules/common/components/ui-kit/custom-switch.vue';
import MarketsService, { MarketsServiceS } from '@/modules/markets/markets.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import GRAPH_TYPE from '@/modules/markets/constants/graph-types.constant';

@Component({
    components: {
        CustomSwitch,
    },
})
export default class MarketsGraphTypeChanger extends Vue {
    @inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @inject(MarketsServiceS) private marketsService!: MarketsService;

    @Prop({
        type: String,
        required: true,
    })
    provider!: string;

    get value(): boolean {
        return this.marketsService.getGraphType(this.provider) === GRAPH_TYPE.HOTELS;
    }
}
