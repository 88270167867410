
import { Component, Mixins, Vue } from 'vue-property-decorator';
import MarketsGraphRange from '@/modules/markets/components/graph/markets-graph-range.vue';
import DateDocumentFilter from '@/modules/document-filters/components/date-document-filter.vue';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import MarketsHeader from '@/modules/markets/components/markets-header.vue';
import MarketsActions from '@/modules/markets/components/markets-actions.vue';
import MarketsGraphSet from '@/modules/markets/components/graph/markets-graph-set.vue';
import MarketsOtelMixin from '@/modules/open-telemetry/mixins/markets/markets-page-otel-logs.mixin';

@Component({
    components: {
        MarketsActions,
        DateDocumentFilter,
        PageWrapper,
        MarketsHeader,
        MarketsGraphRange,
        MarketsGraphSet,
    },
})
export default class MarketsGraphPositionPage extends Mixins(MarketsOtelMixin) {}
